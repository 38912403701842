<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;直播配置</span>
    </div>
    <el-form :model="form" ref="form" label-width="250px" size="small" style="width:600px">
      <el-form-item label="网络备用源数量">
        <el-input-number v-model.number="form.standby_num" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="有效源参考值">
        <el-input-number v-model.number="form.active_rate" :min="0" :step="0.01" :max="1"></el-input-number>
      </el-form-item>
      <el-form-item label="无操作退出直播时间（分钟）">
        <el-input-number v-model.number="form.wait_time" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="节目表加密密钥">
        <el-input v-model="form.rand_key">
          <template slot="append">
            <el-button style="color:#5e72f9;width:110px" @click="randomKey">随机生成</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="直播解码模式">
        <el-select v-model="form.decoder" placeholder="请选择" style="width:100%">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="直播超时跳转时间（秒）">
        <el-input-number v-model.number="form.time_out" :min="0" :step="5" :max="30"></el-input-number>
      </el-form-item>
      <el-form-item label="信息弹窗显示文字内容">
        <el-input v-model="form.exit_dialog_info"></el-input>
      </el-form-item>
      <el-form-item label="节目表自动更新">
        <el-radio-group v-model="form.auto_update" size="mini">
          <el-radio-button label="1">是</el-radio-button>
          <el-radio-button label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="节目表自动更新间隔时间（分）">
        <el-input-number v-model.number="form.update_interval" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="进制数据节目EPG_KEY">
        <el-input v-model="form.tv_program_key"></el-input>
      </el-form-item>
      <el-form-item label="nps地址">
        <el-input v-model="form.nps_address"></el-input>
      </el-form-item>
      <el-form-item label="nps端口">
        <el-input v-model="form.nps_port"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        standby_num: "",
        active_rate: "",
        wait_time: "",
        rand_key: "",
        decoder: "",
        time_out: "",
        exit_dialog_info: "",
        auto_update: "",
        update_interval: "",
        tv_program_key: "",
        nps_address: "",
        nps_port: ""
      },
      options: [
        { value: "0", label: "智能解码" },
        { value: "1", label: "IJK硬解" },
        { value: "2", label: "原生解码" }
      ]
    };
  },
  created() {
    this.initLive();
  },
  methods: {
    initLive() {
      getSystemConfigApi({ type: "live" }).then(res => {
        if (res) {
          this.form = res.data;
        }
      });
    },
    randomKey() {
      var $int = "12345678910123456789101234";
      var $minchars = "abcdefghijklmnopqrstuvwxyz";
      var $chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var maxPos = $chars.length;
      var pwd = "";
      for (var i = 0; i < 16; i++) {
        var num = Math.floor(Math.random() * 3);
        if (num == 0) {
          pwd += $int.charAt(Math.floor(Math.random() * maxPos));
        } else if (num == 1) {
          pwd += $minchars.charAt(Math.floor(Math.random() * maxPos));
        } else {
          pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
      }
      this.form.rand_key=pwd;
    },
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>